import { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { FR, GB } from "country-flag-icons/react/1x1";

export default function NavBar() {
    const [activeLink, setActiveLink] = useState("Accueil");
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState("fr");

    const changeLangue = (lang) => {
        setLang(lang);
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            sections.forEach((section) => {
                const top = section.offsetTop;
                const height = section.offsetHeight;
                if (window.scrollY >= top && window.scrollY < top + height) {
                    setActiveLink(section.id);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setActiveLink]);
    const [navbar, setNavbar] = useState(false);
    const active =
        " p-2 text-rouge font-bold font-inter text-1xl hover:text-rouge text-nowrap";
    const inactive =
        " p-2 text-gray-600 font-bold font-inter text-1xl hover:text-rouge text-nowrap";
    return (
        <nav className="fixed bg-white inset-0  z-50 h-max min-w-full rounded-none px-4 py-2 lg:px-8 ">
            <div className="justify-between px-3 mx-auto lg:max-w-7xl lg:items-center lg:flex lg:px-1">
                <div>
                    <div className="flex items-center justify-between py-3 lg:py-1 lg:block">
                        <div className="lg:min-w-34 lg:w-34 w-24 flex justify-center items-center">
                            <a
                                href="/"
                                className=" font-inter font-bold text-3xl text-vert"
                            >
                                {/* InHub */}
                                <img className="w-full object-center rounded-full" src={process.env.PUBLIC_URL + "/images/newLogo.png"} alt="Logo"></img>
                            </a>
                        </div>
                        <div className="lg:hidden">
                            <button
                                className="p-2 text-white font-bold uppercase rounded-md outline-none focus:border-gray-400 bg-[#408A7E] focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                menu
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        <ul className=" lg:ml-60 items-center justify-center space-y-8 lg:flex lg:space-x-6 lg:space-y-0">
                            <li
                                className={
                                    activeLink === "Accueil" ? active : inactive
                                }
                            >
                                <a href="/#Accueil">{t("navbar.0")}</a>
                            </li>

                            <li
                                className={
                                    activeLink === "Apropos" ? active : inactive
                                }
                            >
                                <a href="/#Apropos">{t("navbar.1")}</a>
                            </li>
                            <li
                                className={
                                    activeLink === "Metier" ? active : inactive
                                }
                            >
                                <a href="/#Metier">{t("navbar.2")}</a>
                            </li>
                            <li
                                className={
                                    activeLink === "Service" ? active : inactive
                                }
                            >
                                <a href="/#Service">{t("navbar.3")}</a>
                            </li>
                            <li
                                className={
                                    activeLink === "Partenaires"
                                        ? active
                                        : inactive
                                }
                            >
                                <a href="/#Partenaires">{t("navbar.4")}</a>
                            </li>
                        </ul>

                        {/* <div className="mt-3 space-y-2 lg:hidden md:inline-block">
                    <Link
                        to="/auth/login"
                        className="inline-block w-full px-4 py-2 text-center text-white bg-gray-800 rounded-md shadow hover:bg-gray-900"
                    >
                        connecter
                    </Link>
                    <Link
                        to="/client/inscription"
                        className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                    >
                        s'inscrire
                    </Link>
                </div> */}
                    </div>
                </div>
                <div className=" w-full flex items-center justify-around lg:justify-end space-x-3 ">
                    <Button className=" bg-white border-0 shadow-none">
                        {lang === "fr" ? (
                            <GB
                                onClick={() => changeLangue("en")}
                                className="w-8 shadow-none border-none "
                            />
                        ) : (
                            <FR
                                onClick={() => changeLangue("fr")}
                                className="w-8 shadow-none border-none"
                            />
                        )}
                    </Button>
                    <Button className="bg-[#408A7E]">
                        {" "}
                        <a href="/#Contact">{t("navbar.5")}</a>
                    </Button>
                </div>
                {/* <div className="hidden space-x-2 md:inline-block">
                    <Link
                        to="/auth/login"
                        className="px-4 py-2 text-white bg-gray-800 rounded-md shadow hover:bg-gray-900"
                    >
                        connecter
                    </Link>
                    <Link
                        to="/client/inscription"
                        className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                    >
                        s'inscrire
                    </Link>
                </div> */}
            </div>
        </nav>
    );
}
