import React from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

function Pourquoi() {
    const screenWidth = window.innerWidth;
    const adjacent = screenWidth / 3;
    const opposite = 250;
    const { t } = useTranslation();
    return (
        <section
            id="Partenaires"
            className="min-h-screen h-full  w-full bg-vertClair relative flex flex-1 shrink-0 overflow-hidden py-8 lg:py-8 lg:pl-24 font-inter"
        >
            <div className=" bg-white w-full max-h-min z-10 relative p-8">
                <img
                    src="./images/pourquoi.png"
                    alt="immeuble"
                    className=" -z-10 absolute bottom-0 right-0 h-96 opacity-10 lg:opacity-100"
                />
                <h1 className="text-2xl font-bold text-vert font-inter z-10">
                    {t("pourquoi.titre")}
                </h1>
                <div className=" z-10 w-full h-full lg:grid grid-cols-2 flex flex-col items-center justify-between gap-4">
                    <div>
                        <h1 className=" font-bold text-xl flex items-center flex-nowrap mb-6">
                            <span className="mr-4">
                                <FaRegCircleXmark className=" text-3xl text-rouge bg-red-100 rounded-full" />
                            </span>{" "}
                            {t("pourquoi.titre1")}
                        </h1>
                        <p className="max-w-lg pl-10">
                            <ul className=" ml-10 p-0 list-disc ">
                                <li> {t("pourquoi.options1.0")}</li>
                                <li> {t("pourquoi.options1.1")}</li>
                                <li> {t("pourquoi.options1.2")}</li>
                            </ul>
                        </p>
                    </div>
                    <div>
                        <h1 className=" font-bold text-xl flex items-center flex-nowrap mb-6">
                            <span className="mr-4">
                                <FaRegCircleXmark className=" text-3xl text-rouge bg-red-100 rounded-full" />
                            </span>{" "}
                            {t("pourquoi.titre2")}
                        </h1>
                        <p className="max-w-lg pl-10">
                            <ul className=" ml-10 p-0 list-disc ">
                                <li> {t("pourquoi.options2.0")}</li>
                                <li> {t("pourquoi.options2.1")}</li>
                                <li> {t("pourquoi.options2.2")}</li>
                            </ul>
                        </p>
                    </div>
                    <div>
                        <h1 className=" font-bold text-xl flex items-center flex-nowrap mb-6">
                            <span className="mr-4">
                                <FaRegCircleXmark className=" text-3xl text-rouge bg-red-100 rounded-full" />
                            </span>{" "}
                            {t("pourquoi.titre3")}
                        </h1>
                        <p className="max-w-lg pl-10">
                            <ul className=" ml-10 p-0 list-disc ">
                                <li> {t("pourquoi.options3.0")}</li>
                                <li> {t("pourquoi.options3.1")}</li>
                                <li> {t("pourquoi.options3.2")}</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 right-0 z-0"
                width={adjacent}
                height={opposite}
            >
                <polygon
                    points={`0,${opposite} ${adjacent},${opposite} ${adjacent},0`}
                    style={{
                        fill: "white",
                        stroke: "none",
                    }}
                />
                <line
                    x1={adjacent}
                    y1={opposite}
                    x2={adjacent}
                    y2="0"
                    style={{ stroke: "none" }}
                />
            </svg>
        </section>
    );
}

export default Pourquoi;
