import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
const Service = () => {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    const adjacent = screenWidth / 3;
    const opposite = 250;
    const [hoveredCard, setHoveredCard] = useState(2);
    const style = "max-w-xs p-6 rounded-md shadow-md bg-gris min-h-[435px]";
    const hoverStyle =
        "max-w-xs p-6 rounded-xl shadow-md bg-vert text-white min-h-[435px]";
    return (
        <section
            id="Service"
            className="relative bg-white flex flex-col justify-center items-center min-h-screen lg:h-screen overflow-hidden py-12"
        >
            <svg
                width="478"
                height="248"
                viewBox="0 0 678 248"
                fill="none"
                className=" absolute -top-12 left-0 z-0 rotate-4"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="-79"
                    y="208.871"
                    width="785.394"
                    height="40.9441"
                    transform="rotate(-18.92 -79 208.871)"
                    fill="#EE1B22"
                />
            </svg>

            <h1 className="text-2xl text-center font-bold text-vert font-inter z-10">
                {t("services.titre")}
            </h1>

            <div className="flex flex-col lg:flex-row justify-evenly items-center font-inter w-full  z-10 space-y-8 ">
                <Fade left>
                    <div
                        className={hoveredCard === 1 ? hoverStyle : style}
                        onMouseEnter={() => setHoveredCard(1)}
                        onMouseLeave={() => setHoveredCard(2)}
                    >
                        <img
                            src="./images/service1.png"
                            alt=""
                            className="object-cover object-center w-full rounded-md  bg-gray-500"
                        />
                        <div className="mt-6 mb-2">
                            <h2
                                className={
                                    hoveredCard === 1
                                        ? `text-xl font-semibold tracking-wide text-white`
                                        : `text-xl font-semibold tracking-wide text-vert`
                                }
                            >
                                {t("services.titre1")}
                            </h2>
                        </div>
                        <p className="">{t("services.texte1")}</p>
                        {/* <Button
                            className={
                                hoveredCard === 1
                                    ? "bg-white text-vert mt-4 absolute bottom-6"
                                    : "bg-vert mt-4 absolute bottom-6"
                            }
                        >
                            Read More
                        </Button> */}
                    </div>
                </Fade>
                <Fade top>
                    <div
                        className={hoveredCard === 2 ? hoverStyle : style}
                        onMouseEnter={() => setHoveredCard(2)}
                        onMouseLeave={() => setHoveredCard(2)}
                    >
                        <img
                            src="./images/service2.png"
                            alt=""
                            className="object-cover object-center w-full rounded-md  bg-gray-500"
                        />
                        <div className="mt-6 mb-2">
                            <h2
                                className={
                                    hoveredCard === 2
                                        ? `text-xl font-semibold tracking-wide text-white`
                                        : `text-xl font-semibold tracking-wide text-vert`
                                }
                            >
                                {t("services.titre2")}
                            </h2>
                        </div>
                        <p className="">{t("services.texte2")}</p>
                        {/* <Button
                            className={
                                hoveredCard === 2
                                    ? "bg-white text-vert mt-4 absolute bottom-6"
                                    : "bg-vert mt-4 absolute bottom-6"
                            }
                        >
                            Read More
                        </Button> */}
                    </div>
                </Fade>
                <Fade right>
                    <div
                        className={hoveredCard === 3 ? hoverStyle : style}
                        onMouseEnter={() => setHoveredCard(3)}
                        onMouseLeave={() => setHoveredCard(2)}
                    >
                        <img
                            src="./images/service3.png"
                            alt=""
                            className="object-cover object-center w-full rounded-md  bg-gray-500"
                        />
                        <div className="mt-6 mb-2">
                            <h2
                                className={
                                    hoveredCard === 3
                                        ? `text-xl font-semibold tracking-wide text-white`
                                        : `text-xl font-semibold tracking-wide text-vert`
                                }
                            >
                                {t("services.titre3")}
                            </h2>
                        </div>
                        <p className="">{t("services.texte3")}</p>
                        {/* <Button
                            className={
                                hoveredCard === 3
                                    ? "bg-white text-vert mt-4 absolute bottom-6"
                                    : "bg-vert mt-4 absolute bottom-6"
                            }
                        >
                            Read More
                        </Button> */}
                    </div>
                </Fade>

                {/* <Fade left>
                    <Card
                        className={`mt-6 w-96 ${
                            hoveredCard === 1 ? "bg-[#408A7E]" : "bg-gray-200"
                        }`}
                        onMouseEnter={() => setHoveredCard(1)}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <CardHeader color="blue-gray" className="relative h-56">
                            <img
                                src="./images/service1.png"
                                className="w-full h-full object-cover mb-4 mx-auto"
                                alt="Avatar"
                            />
                        </CardHeader>
                        <CardBody>
                            <Typography
                                variant="h5"
                                color="green"
                                className="mb-2"
                            >
                                1.Réponses et solutions simples
                            </Typography>
                            <Typography>
                                Des solutions à vos problémes industriels ....
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0 text-green">
                            <Button className="bg-[#408A7E]">Read More</Button>
                        </CardFooter>
                    </Card>
                </Fade> */}
                {/* <Fade top>
                    <Card
                        className={`mt-6 w-96 ${
                            hoveredCard === 2 ? "bg-[#408A7E]" : "bg-gray-200"
                        }`}
                        onMouseEnter={() => setHoveredCard(2)}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <CardHeader color="blue-gray" className="relative h-56">
                            <img
                                src="./images/service2.png"
                                className="w-full h-full object-cover mb-4 mx-auto"
                                alt="Avatar"
                            />
                        </CardHeader>
                        <CardBody>
                            <Typography
                                variant="h5"
                                color="green"
                                className="mb-2"
                            >
                                2.Un réseau d'experts à votre disposition
                            </Typography>
                            <Typography>
                                Des entreprises expertes dans leurs metiers vous
                                accompagnement à la concretisations de vos idées
                                ....
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0 text-green">
                            <Button className="bg-[#408A7E]">Read More</Button>
                        </CardFooter>
                    </Card>
                </Fade> */}
                {/* <Fade right>
                    <Card
                        className={`mt-6 w-96 ${
                            hoveredCard === 3 ? "bg-[#408A7E]" : "bg-gray-200"
                        }`}
                        onMouseEnter={() => setHoveredCard(3)}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <CardHeader color="blue-gray" className="relative h-56">
                            <img
                                src="./images/service3.png"
                                className="w-full h-full object-cover mb-4 mx-auto"
                                alt="Avatar"
                            />
                        </CardHeader>
                        <CardBody>
                            <Typography
                                variant="h5"
                                color="green"
                                className="mb-2"
                            >
                                3.Opportunités d'affaires internationales
                            </Typography>
                            <Typography>
                                Simple Business Solutions has generated
                                thousands of dollars in annual cost savings ....
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0 text-green">
                            <Button className="bg-[#408A7E]">Read More</Button>
                        </CardFooter>
                    </Card>
                </Fade> */}
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 right-0 z-0 "
                width={adjacent}
                height={opposite}
            >
                <polygon
                    points={`0,${opposite} ${adjacent},${opposite} ${adjacent},0`}
                    style={{
                        fill: "#408A7E8A",
                        stroke: "none",
                    }}
                />
                <line
                    x1={adjacent}
                    y1={opposite}
                    x2={adjacent}
                    y2="0"
                    style={{ stroke: "none" }}
                />
            </svg>
        </section>
    );
};

export default Service;
