import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import ScrollAnimation from 'react-animate-on-scroll';
import Accueil from "./components/Accueil";
// import Apropos from './components/Apropos';
// import Contact from './components/Contact';

// import NavBar from "./components/Navbar";
// import Projets from './components/Partenaires';
// import Service from './components/Service';

function App() {
    return (
        <div className=" w-screnn overflow-hidden">
            <Router>
                <Routes>
                    <Route path="/" element={<Accueil />}></Route>

                    
                </Routes>
            </Router>
        </div>
    );
}

export default App;
