import React from "react";
import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
function Hero() {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    const adjacent = screenWidth / 3;
    const opposite = 250;
    return (
        <section
            id="Accueil"
            className="min-h-screen lg:h-screen relative flex flex-1 shrink-0 items-center justify-start overflow-hidden bg-gray-100 mt-10 py-16 md:px-16 md:py-20 xl:py-48 font-urbanist "
        >
            {/* image - start */}
            <img
                src="./images/ilus.png"
                alt="immeuble"
                className="absolute inset-0 h-full w-full object-cover lg:object-fill object-center"
            />
            {/* image - end */}
            {/* overlay - start */}
            <div className="absolute inset-0  bg-[#A3C4A8] mix-blend-multiply" />
            {/* overlay - end */}
            {/* text start */}
            <div className="relative flex flex-col items-start p-4 z-10 ">
                <h1 className="mb-8 text-center text-3xl font-bold text-white sm:text-3xl md:mb-12 md:text-4xl">
                    {t("hero.titre")}
                </h1>
                <p className="mb-4 text-lg text-white sm:text-xl md:mb-8 max-w-xl">
                    {t("hero.texte1")}
                    <br /> {t("hero.texte2")}
                    <strong>{t("hero.texte3")}</strong>
                </p>
                <div className="flex w-full flex-col gap-2.5 sm:flex-row sm:justify-start font-inter ">
                    <Button className=" border-2 border-white bg-transparent">
                        <a href="/#Apropos" className="px-2 py-2">
                            {t("navbar.1")}
                        </a>
                    </Button>

                    <Button className="bg-white text-rouge">
                        <a href="/#Contact" className="px-2 py-2">
                            {t("navbar.5")}
                        </a>
                    </Button>
                </div>
            </div>
            {/* text end */}

            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 right-0 z-0"
                width={adjacent}
                height={opposite}
            >
                <polygon
                    points={`0,${opposite} ${adjacent},${opposite} ${adjacent},0`}
                    style={{
                        fill: "white",
                        stroke: "none",
                    }}
                />
                <line
                    x1={adjacent}
                    y1={opposite}
                    x2={adjacent}
                    y2="0"
                    style={{ stroke: "none" }}
                />
            </svg>
        </section>
    );
}

export default Hero;
