import i18n from "i18next";
import Languagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationFr from "./translation/translationFr.json";
import translationEn from "./translation/translationEn.json";
i18n.use(Languagedetector)
    .use(initReactI18next)
    .init({
        debug: true,
        lng: "fr",
        fallbackLng: "fr",
        returnObjects: true,
        resources: {
            fr: {
                translation: translationFr,
            },
            en: {
                translation: translationEn,
            },
        },
    });
