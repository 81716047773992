import React from "react";
// import { Link } from 'react-router-dom'
import Apropos from "./Apropos";
import Footer from "./Footer";
import NavBar from "./Navbar";
import Service from "./Service";
import Partenaires from "./Partenaires";
import Hero from "./Hero";
import Pourquoi from "./Pourquoi";
import Metier from "./Metier";

const Accueil = () => {
    return (
        <>
            <NavBar />
            <Hero />
            <Apropos />
            <Metier/>
            <Service />
            <Pourquoi/>
            <Partenaires />
            <Footer />
            {/* <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">GESTION DE PROJETS</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                       Prise en charge <br />
                       compréhension et intégration du projet client 
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      ETUDES & CONCEPTION
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Prendre en charge , <br />
                      analyser et formaliser des cahiers des charges techniques
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      CONSEILS EN ACTIVITES TRANSVERSES
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                        Marketing <br />
                        Communication 
                      .Commerce
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </section>*/}
        </>
    );
};

export default Accueil;
