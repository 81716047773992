import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Footer = () => {
    const [activeLink, setActiveLink] = useState("Accueil");
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [msg, setMsg] = useState("");
    const [nom, setNom] = useState("");
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        const serviceID = "service_bbk44ko";
        const templateID = "template_sqb1rsq";
        const publicID = "AIzcKgzrcy4p9QAfB";

        emailjs
            .sendForm(serviceID, templateID, form.current, {
                publicKey: publicID,
            })
            .then(
                () => {
                    alert("Message envoyé!");
                    setEmail("");
                    setTel("");
                    setMsg("");
                    setNom("");
                },
                (error) => {
                    alert("Erreur lors de l'envoie!");
                    console.log(error);
                }
            );
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            sections.forEach((section) => {
                const top = section.offsetTop;
                const height = section.offsetHeight;
                if (window.scrollY >= top && window.scrollY < top + height) {
                    setActiveLink(section.id);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setActiveLink]);
    const active = " p-2 text-rouge font-medium  text-lg hover:text-rouge";
    const inactive = " p-2  font-bold  text-lg hover:text-rouge";
    return (
        <footer id="Contact" className="bg-vert font-work-sans">
            <div className="container px-5 py-24 mx-auto">
                <div className=" flex-col-reverse md:flex-row flex flex-wrap md:text-left order-first gap-8 md:gap-0">
                    <div className=" md:w-2/6 w-full px-4 flex flex-col items-center justify-center text-center text-white">
                        <div>
                            {" "}
                            <a
                                href="/"
                                className=" font-inter font-bold text-3xl text-white mb-4 w-full mx-auto"
                            >
                                 InHub 
                                {/* <img
                                    className="w-44"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/images/newLogos2.png"
                                    }
                                    alt="Logo"
                                ></img> */}
                            </a>
                        </div>
                        <h3 className=" -mt-2 w-full text-center ">
                            {t("footer.texte")}
                        </h3>
                        <h3 className=" w-full text-center ">
                            contact@ecosysteme-inhub.com
                        </h3>
                        <h3 className=" w-full text-center ">
                            +33 647 19 46 78
                        </h3>

                        <nav className="list-none mt-10 w-full text-center">
                            <li className="uppercase font-bold text-xl">
                                {t("footer.nav")}
                            </li>
                            <li
                                className={
                                    activeLink === "Accueil" ? active : inactive
                                }
                            >
                                <a href="/#Accueil">{t("navbar.0")}</a>
                            </li>

                            <li
                                className={
                                    activeLink === "Apropos" ? active : inactive
                                }
                            >
                                <a href="/#Apropos">{t("navbar.1")}</a>
                            </li>
                            <li
                                className={
                                    activeLink === "Metier" ? active : inactive
                                }
                            >
                                <a href="/#Metier">{t("navbar.2")}</a>
                            </li>
                            <li
                                className={
                                    activeLink === "Service" ? active : inactive
                                }
                            >
                                <a href="/#Service">{t("navbar.3")}</a>
                            </li>
                            <li
                                className={
                                    activeLink === "Partenaires"
                                        ? active
                                        : inactive
                                }
                            >
                                <a href="/#Partenaires">{t("navbar.4")}</a>
                            </li>
                        </nav>
                    </div>

                    <div className="md:w-4/6 px-4 space-y-3 flex flex-col items-center justify-between ">
                        <h2 className="title-font font-medium text-white text-2xl lg:text-4xl font-inter mb-3">
                            {t("footer.titre")}
                        </h2>
                        <form
                            ref={form}
                            onSubmit={sendEmail}
                            className="w-full h-full max-w-96 gap-y-6 lg:gap-x-8 flex flex-wrap items-center justify-between"
                        >
                            <div className="text-white font-medium w-full">
                                <label htmlFor="nom" className="text-lg">
                                    {t("footer.nom")}
                                </label>
                                <input
                                    id="nom"
                                    type="txt"
                                    name="nom"
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                    required
                                    // placeholder={t("footer.placeNom")}
                                    className="placeholder-white w-full lg:w-72 px-2 py-1 bg-transparent text-white border-b-2 border-white outline-none mb-3 lg:mb-0"
                                />
                            </div>
                            <div className=" flex max-w-96 gap-y-6 lg: space-x-2 lg:flex-nowrap flex-wrap">
                                <div className="text-white font-medium w-full">
                                    <label htmlFor="email" className="text-lg">
                                        {t("footer.email")}
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        required
                                        // placeholder={t("footer.placeEmail")}
                                        className="placeholder-white w-full lg:w-72 px-2 py-1 bg-transparent text-white border-b-2 border-white outline-none mb-3 lg:mb-0"
                                    />
                                </div>
                                <div className="text-white font-medium w-full">
                                    <label htmlFor="tel" className="text-lg">
                                        {t("footer.tel")}
                                    </label>
                                    <input
                                        id="tel"
                                        type="tel"
                                        name="tel"
                                        value={tel}
                                        onChange={(e) => setTel(e.target.value)}
                                        required
                                        // placeholder={t("footer.placeTel")}
                                        className="placeholder-white w-full lg:w-72 px-2 py-1 bg-transparent text-white border-b-2 border-white outline-none mb-3 lg:mb-0"
                                    />
                                </div>
                            </div>
                            <div className="text-white font-medium w-full  min-w-xl ">
                                <label for="message" className="text-lg">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    rows="3"
                                    name="message"
                                    value={msg}
                                    onChange={(e) => setMsg(e.target.value)}
                                    className="w-full px-2 py-1 border-2 border-white bg-transparent outline-none"
                                ></textarea>
                            </div>
                            <div className="w-full min-w-xl flex">
                                <button
                                    type="submit"
                                    className=" px-4 py-1 text-lg font-bold tracking-wide uppercase rounded bg-white text-rouge"
                                >
                                    {t("footer.envoyer")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-vert font-work-sans text-white font-medium">
                <div className="container space-x-2 px-5 py-6 mx-auto flex items-center justify-center">
                    <span>&copy;</span>
                    <span> Copyright </span>
                    <span>{new Date().getFullYear()}</span>
                    <span>InHub</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
