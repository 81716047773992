import React from "react";
import { useTranslation } from "react-i18next";

const Apropos = () => {
    const { t } = useTranslation();
    return (
        <section
            id="Apropos"
            className=" min-h-screen lg:h-screen w-full flex items-center justify-center"
        >
            <div className="h-full w-full flex flex-col items-center justify-center p-6 mx-auto sm:py-8 lg:flex-row">
                <div className=" h-48 overflow-hidden shadow-lg lg:h-auto xl:w-5/12">
                    <img
                        src="./images/Apropos.png"
                        alt=""
                        className="h-full w-full object-contain object-center"
                    />
                </div>
                <div className=" lg:w-3/6 flex flex-col justify-start items-start p-6 rounded-sm lg:text-left">
                    <h1 className="text-2xl font-bold leading-none  font-urbanist">
                        {t("apropos.titre")} <br />
                        In<span className="text-rouge">H</span>ub
                    </h1>
                    <p className="mt-6 mb-4 text-sm  max-w-screen-md text-justify">
                        {t("apropos.texte0")}
                    </p>
                    <p className=" mb-4 text-sm  max-w-screen-md text-justify">
                        {t("apropos.texte1")}
                    </p>
                    <p className="text-sm  max-w-screen-md text-justify">
                        {t("apropos.texte2")}{" "}
                        <strong>{t("apropos.texte3")}</strong>
                    </p>
                </div>
            </div>
        </section>

        //     <>
        //    {/*<div className="flex justify-center items-center">
        //   <svg width="1000" height="100" className="mb-0 mr-4">
        //     <rect x="-500" y="90" width="800" height="20" fill="red" transform="rotate(-20)" />
        //   </svg>
        //   <div>
        //     <hr className="bg-blue-400 w-40 h-2 mt-0 mb-0" />
        //     <h1 className="text-3xl text-center mt-0 mb-0">A Propos de InHub</h1>
        //   </div>
        // </div>*/}
        //  <section className='grid justify-items-center p-5 m-1 '>

        //       <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
        //           <div className="container ">
        //             <div className=" flex flex-wrap items-center justify-between">
        //               <div className="w-full px-4 lg:w-6/12">
        //                 {/*<div className="-mx-3 flex items-center sm:-mx-4">
        //                   <div className="w-full px-3 sm:px-4 xl:w-1/2">
        //                     <div className="py-3 sm:py-4">
        //                       <img
        //                         src="https://www.cnfdi.com/img/images/Blog/Articles%20de%20fond/R%C3%A9diger%20son%20projet%20pro.png"
        //                         alt=""
        //                         className="w-full rounded-2xl"
        //                       />
        //                     </div>
        //                     <div className="py-3 sm:py-4">
        //                       <img
        //                         src="https://www.dynamique-mag.com/wp-content/uploads/171d6b1838a829d20362cf76c244a3c6-780x405.jpg"
        //                         alt=""
        //                         className="w-full rounded-2xl"
        //                       />
        //                     </div>
        //                   </div>
        //                   <div className="w-full px-3 sm:px-4 xl:w-1/2">
        //                     <div className="relative z-10 my-4">
        //                       <img
        //                         src="./images/image1.jpg"
        //                         alt=""
        //                         className="w-full rounded-2xl"
        //                       />
        //                       <span className="absolute -right-7 -bottom-7 z-[-1]">
        //                         <svg
        //                           width="134"
        //                           height="106"
        //                           viewBox="0 0 134 106"
        //                           fill="none"
        //                           xmlns="http://www.w3.org/2000/svg"
        //                         >

        //                         </svg>
        //                       </span>
        //                     </div>
        //                   </div>
        //   </div>*/}
        //                      <img
        //                         src="./images/Apropos.png"
        //                         alt=""
        //                         className="w-full rounded-2xl"
        //                       />
        //               </div>
        //               <div className="w-full px-4 lg:w-1/2 xl:w-5/12 mr-8">
        //                 <div className="mt-0 lg:mt-0">

        //                   <p className="text-body-color mb-2 text-base">
        //                   INHUB, ce rêve où le champ du possible est décuplé par le nombre d’acteurs croyant à la même ambition ALLER PLUS HAUT GRACE A UN COLLECTIF.
        //                   </p>
        //                   <p>
        //                       Avec l’humain comme dénominateur commun, nous partageons chacun des valeurs fortes à toute la communauté et sommes acteurs d’un développement collectif
        //                   </p>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </section>
        //         {/* <div className='grid justify-items-center '>
        //             <h1 className='text-3xl text-center mt-5'>À PROPOS</h1>
        //             <hr className=" bg-blue-400 content-center text dark:bg-blue-700 w-24 h-1 mt-3" />
        //         </div>
        //       <div className="rounded-lg shadow-lg  flex flex-row bg-gray-200 mt-5">
        //         <div className="lg:w-3/5 w-full   lg:to-transparent rounded-lg text-black p-12">
        //           <div className="lg:w-1/2 ml-32">

        //             <p>
        //                 G-NI's FACTORY est née du partenariat entre l’École Supérieure Polytechnique de Dakar et Nicomatic SA.
        //                 Localisée à la FORGE au sein de l’École sur la Corniche Ouest , l' initiative vient :
        //             </p>
        //             <ol className='list-disc ml-2'>
        //                 <li className='ml-2'>
        //                     Faire le lien entre la formation académique et la réalité professionnelle à travers des projets industriels
        //                 </li>
        //                 <li className='ml-2'>
        //                 Apporter un accompagnement transverses aux projets des polytechniciens entrepreneurs incubés à la FORGE.
        //                 </li>
        //             </ol>
        //             <p className=" leading-relaxed">
        //                 Nous sommes une entité multidisciplinaire dont les activités sont orientées dans tous les domaines à forts enjeux de développement au Sénégal : l'agrobusiness , la santé , l'industrie , les nouvelles technologies.La G-NI's FACTORY se veut être une accompagnatrice , avec une forte expérience , de l' écosystème entrepreneurial et industriel dans la réalisation des rêves les plus innovants.
        //                 Nos expertises éprouvées dans la Gestion de Projet, l’Étude et la Conception de solutions techniques et le Conseil en activités transverses industrielles nous porte dans l'ambition d'être votre levier de développement . A VOUS : start-ups et entreprises à la recherche de solutions créatives , nous voulons avoir un impact positif dans votre montée en maturité vers une croissance saine et durable.
        //                 Et cet impact , la G-NI's FACTORY se veut de l'apporter dans l'industrialisation du Sénégal qui est de 23,50% du PIB et faciliter l'auto emploi chez les créateurs de solutions.
        //             </p>

        //           </div>
        //         </div>
        //         <div className="lg:w-2/5 w-full lg:flex lg:flex-row hidden m-5  mt-20 ">
        //           <img src="https://static1.s123-cdn-static-a.com/uploads/7114917/400_637df357c0ba8.jpg" alt='' className="h-full rounded-md" />
        //         </div>
        //       </div> */}
        //       </section>
    );
};

export default Apropos;
