import React from "react";
import { useTranslation } from "react-i18next";
import { LuBrain } from "react-icons/lu";
import { MdOutlineFactory } from "react-icons/md";
import { BiPieChartAlt2 } from "react-icons/bi";
import { Fade } from "react-reveal";

function Metier() {
    const { t } = useTranslation();
    const screenWidth = window.innerWidth;
    const adjacent = screenWidth / 4;
    const opposite = 150;
    return (
        <section
            id="Metier"
            className=" min-h-screen lg:h-screen relative font-inter overflow-hidden"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 right-0 z-0 -rotate-180"
                width={adjacent}
                height={opposite}
            >
                <polygon
                    points={`0,0 0,${opposite} ${adjacent},${opposite}`}
                    style={{
                        fill: "#408A7E8A",
                        stroke: "none",
                    }}
                />
                <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2={opposite}
                    style={{ stroke: "none" }}
                />
            </svg>

            <svg
                width="478"
                height="248"
                viewBox="0 0 678 248"
                fill="none"
                className=" absolute -top-12 left-0 -z-10"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="-79"
                    y="208.871"
                    width="785.394"
                    height="40.9441"
                    transform="rotate(-18.92 -79 208.871)"
                    fill="#EE1B22"
                />
            </svg>
            <h1 className="text-2xl -mb-8  text-center font-bold text-vert font-inter z-10">
                {t("metier.titre")}
            </h1>
            <div className="container  mt-16 px-5 py-6 mx-auto flex flex-wrap">
                <Fade right>
                    <div className="flex relative pt-5 pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-vert text-white relative z-10 title-font font-medium text-sm">
                            1
                        </div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div className="flex-shrink-0 w-20 h-20 bg-vertClair text-white rounded-full inline-flex items-center justify-center">
                                <LuBrain className=" text-6xl font-bold" />
                            </div>
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-lg">
                                    {t("metier.titre1")}
                                </h2>
                                <p className="leading-relaxed">
                                    <ul className=" ml-10 p-0 list-disc ">
                                        <li> {t("metier.options1.0")}</li>
                                        <li> {t("metier.options1.1")}</li>
                                        <li> {t("metier.options1.2")}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade right delay={500}>
                    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-vert text-white relative z-10 title-font font-medium text-sm">
                            2
                        </div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div className="flex-shrink-0 w-20 h-20 bg-vertClair text-white rounded-full inline-flex items-center justify-center">
                                <MdOutlineFactory className=" text-6xl font-bold" />
                            </div>
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-lg">
                                    {t("metier.titre2")}
                                </h2>
                                <p className="leading-relaxed">
                                    <ul className=" ml-10 p-0 list-disc ">
                                        <li> {t("metier.options2.0")}</li>
                                        <li> {t("metier.options2.1")}</li>
                                        <li> {t("metier.options2.2")}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade right delay={800}>
                    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-vert text-white relative z-10 title-font font-medium text-sm">
                            3
                        </div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div className="flex-shrink-0 w-20 h-20 bg-vertClair text-white rounded-full inline-flex items-center justify-center">
                                <BiPieChartAlt2 className=" text-6xl font-bold" />
                            </div>
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-lg">
                                    {t("metier.titre3")}
                                </h2>
                                <p className="leading-relaxed">
                                    <ul className=" ml-10 p-0 list-disc ">
                                        <li> {t("metier.options3.0")}</li>
                                        <li> {t("metier.options3.1")}</li>
                                        <li> {t("metier.options3.2")}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 right-0 z-0 "
                width={adjacent}
                height={opposite}
            >
                <polygon
                    points={`0,${opposite} ${adjacent},${opposite} ${adjacent},0`}
                    style={{
                        fill: "#408A7E8A",
                        stroke: "none",
                    }}
                />
                <line
                    x1={adjacent}
                    y1={opposite}
                    x2={adjacent}
                    y2="0"
                    style={{ stroke: "none" }}
                />
            </svg>
        </section>
    );
}

export default Metier;
